<template>
<v-container style="margin-top:210px;position:relative;"  fluid>
  <v-card class="rounded-lg" elevation="0" style="position:fixed;top:40px;left:0;right:0; margin:auto;z-index:1000;width:100%;
   background-image: url('img/back_appbar.png');background-color: #fd9f30;background-repeat:no-repeat;background-position-y:-65px;
   background-size: 100% 100%;
   
   ">
          <v-card-title style="
          
          color:white;z-index:1000; align-items:center;justify-content:center;">
            
            <center><v-img v-if="avatar==0" src="img/av_f.png" style="width:74px;height:74px;"></v-img></center>
            <center><v-img v-if="avatar!=0&&avatar!=1" src="img/av_e.png" style="width:74px;height:74px;"></v-img></center>
            <center><v-img v-if="avatar==1" src="img/av_m.png" style="width:74px;height:74px;"></v-img></center>
          </v-card-title>
          <v-card-text >
             <v-row fluid> 
               
                 <v-col cols='12'>

                   <center>
                  <b> {{this.fname}} {{this.lname}}</b>
                   <br>
                   {{this.bday}}
                   <br>
                   {{this.ttype}}
                   <br>
                   <!-- {CHAIN ACCNAME} -->
                   <br>
                   <span style="color:white;"   @click="sex_set(avatar);edit=true;">Редагувати профіль</span>

                   </center>
                </v-col>
             </v-row> 

        
         </v-card-text>

      
        
      </v-card>
  
  <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        >
        
        <v-row fluid>
          <v-col cols="5" style="background-color:#fdaa47;border-radius:15px;"   @click="$router.push({name:'Rate'})">
            <center>
            <v-img src="img/ico_p_rate.png" style="width:74px;height:74px; "></v-img>
            <br>Рейтинг:  <b>{{this.rating}}</b>
            </center>
          </v-col>
           <v-col cols="2"></v-col>
          <v-col cols="5" style="background-color:#fdaa47;border-radius:15px;" >
            <center><v-img src="img/ico_p_balance.png" style="width:74px;height:74px;"  @click="$router.push({name:'Balance'})"></v-img>
            <br>Бали: <b>{{this.points}}</b>
            </center>
            
          </v-col>
        </v-row>
        
        
        
        
        
        </v-col>
        
        
        
        <v-col cols="1"></v-col>
   
   
    </v-row>

  <!-- <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_p_ass.png"
          
         @click.native="$router.push({name:'Asview'})"
          >Мій асортимент
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row> -->
    <!-- <v-row class="ma-1 pa-0" v-if="sku_change_allowed">
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_p_change.png"
          
          @click.native="dialog_skuch=true;"
          >Змінити контракт
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row> -->
    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_p_pass_change.png"
          
          @click.native="pass_change=true"
          >Змінити пароль
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    <v-row class="ma-1 pa-0" v-if="net_allowed">
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_p_network.png"
          
          @click.native="dialog_netw=true"
          >Підключення до мережевого iTeam

        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_p_delete.png"
          
          @click.native="dialog_del=true"
          >Видалення профілю
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>


    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_exit.png" 
           
          @click.native="logout"
          >
          Вийти
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>



<v-dialog
        v-model="dialog_skuch"
        width="300" style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Зміна контракту</v-card-title>
          <v-card-text>
             <v-row> <v-col>
              <p><b> В даному розділі Ви маєте можливість змінити поточний тип контракту</b></p>

                <p>
Увага!<br>

Зміна контракту можлива з 1 по 5 число кожного місяця, за умови, що Ви не виконали жодного завдання в поточному місяці.<br>

Зміна типу контракту підтверджується вашим торговим представником <br>



                </p>
                </v-col>
             </v-row>
           
            
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="dialog_skuch = false;
                $router.push({name: 'Asssel',
                     params: { param_change: true }
                     })"
            >
              Обрати SKU
            </v-btn> 
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_skuch = false"
            >
              Відмінити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog
        v-model="dialog_netw"
         style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Мережевий iTeam</v-card-title>
          <v-card-text>
             <v-row> <v-col>
              <p><b>   Ви перейшли в меню підключення точки продажу до мережі</b></p>

<p>
Увага!<br>

Переконайтесь, що ваша точка продажу може бути підключеною до мережевого iTeam!<br>

Надіславши запит Ви зможете виконувати завдання після його модерації, не раніше першого числа наступного місяця </p>

                </v-col>
             </v-row>
           
            
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="send_net_request();dialog_netw = false"
            >
              Надіслати запит
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_netw = false"
            >
              Відмінити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>


<v-dialog
        v-model="dialog_del"
        width="300" style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Видалити профіль</v-card-title>
          <v-card-text>
             <v-row> <v-col>
                 <p><b>В даному розділі меню Ви маєте можливість видалити свій профіль</b></p>
<br>

Увага!<br>

При видаленні профілю, Ваші персональні дані та накопичені бали будуть видалені автоматично, без можливості поновлення!

                </v-col>
             </v-row>
           <v-row>
             <v-col>
           <v-textarea
            outlined
            name="input-7-4"
            label="Вкажіть причину видалення"
            value="Вкажіть причину видалення"
            v-model="del_reason"
          ></v-textarea>

             </v-col>
           </v-row>
            
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="send_delete_request();dialog_del = false"
            >
              Видалити
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_del = false"
            >
              Відмінити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog
        v-model="pass_change"
        width="300" style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Змінити пароль</v-card-title>
          <v-card-text>
            <v-row fluid>
             
                <v-col cols="12">
                  <v-text-field
                    v-model="password_old"
                    name="Password1n"
                    label="Cтарий пароль"
                    solo
                    rounded
                    @click:append="show1 = !show1"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                  
                  ></v-text-field>
                </v-col>
               
              </v-row>
              <v-row fluid>
                
                <v-col cols="12">
                  <v-text-field
                    v-model="password_new"
                    name="Password1n"
                    label="Новий пароль"
                    solo
                    rounded
                    @click:append="show3 = !show3"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                  
                  ></v-text-field>
                </v-col>
                
              </v-row>
              <v-row fluid>
               
                <v-col cols="12">
                  <v-text-field
                    v-model="password_new_confirm"
                    name="Password2n"
                    label="Підтвердження паролю"
                    solo
                    rounded
                    @click:append="show2 = !show2"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
               
              </v-row>
           
            
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="pass_set_new"
            >
              Змінити
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="pass_change = false"
            >
              Відмінити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>







<v-dialog persistent v-model="edit"   style="z-index:50000;">

         <v-card>
          <v-list>
            <v-list-item>
              <!-- <v-list-item-avatar>
                <img :src="avatar">
              </v-list-item-avatar> -->
  
              <v-list-item-content>
                <v-list-item-title>{{fname}}  {{lname}}</v-list-item-title>
                <!-- <v-list-item-subtitle>{{username}}</v-list-item-subtitle> -->
              </v-list-item-content>
  
              <v-list-item-action>
                
              </v-list-item-action>
            </v-list-item>
          </v-list>
  
          <v-divider></v-divider><br>
          <v-row fluid no-gutters>
            <v-col cols="6"><center>
              <v-img
              v-bind:class="{selected_av: sex==0}"
               id='av_f' @click="sex_set(0)" src='img/av_f.png' contain style='max-height:64px;width:auto;margin-left:20px;margin-right:20px;'></v-img><br><b>Жін.</b></center></v-col>
            <v-col cols="6"><center>
              <v-img 
              v-bind:class="{selected_av: sex==1}"
              id='av_m' @click="sex_set(1)" src='img/av_m.png' contain style='max-height:64px;width:auto;margin-left:20px;margin-right:20px;'></v-img><br><b>Чол.</b></center></v-col>
          </v-row>
          
          <v-list>
              <v-list>
                <v-col cols="12">
                    <v-text-field
                    v-model="fname_e"
                label="Им'я"
                outlined
                ></v-text-field>
                </v-col>
            </v-list>
            
            <v-list>
                <v-col cols="12">
                    <v-text-field
                v-model="lname_e"
                label="Прізвище"
                outlined
                ></v-text-field>
                </v-col>
            </v-list>


            <v-spacer></v-spacer>
      <v-col cols="12" sm=6 md=6 >
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date_e"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date_e"
              label="День народження"
              
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" 
          first-day-of-week="1"
          :header-date-format="getHeaderDateFormat"
           :month-format="getMonthFormat"
        :title-date-format="getTitleDateFormat"
          scrollable color="orange lighten-1">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">Відмінити</v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date)">Зберегти</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

            <!-- <v-list-item>
              <v-list-item-action>
                <v-switch v-model="message" color="purple"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Запомнить этот браузер (30 дней)</v-list-item-title>
            </v-list-item> -->
  
            <!-- <v-list-item>
              <v-list-item-action>
                <v-switch v-model="hints" color="purple"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Уведомления</v-list-item-title>
            </v-list-item> -->

            
          </v-list>
  
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn text @click="edit=false">назад</v-btn>
            <v-btn color="primary" text @click="save_data">Зберегти</v-btn>
          </v-card-actions>
        </v-card>

          </v-dialog>








</v-container>











</template>
<style scoped>
  .selected_av{
    border:4px solid rgb(247 148 29);
    border-radius: 10px;
  }
</style>        
 <script>
import axios from "axios";
import { API_LOCATION } from "../../config";
import btnsqr from "../components/btnsqr.vue";
//import l_switch from "../components/lang_switch.vue";
import JQuery from 'jquery'
window.$ = JQuery
export default {
  watch:{
    


    

  },
  components:{btnsqr},
    computed: {
      selected_sex:function(){
        if(this.sex==0)
        {
          return "selected_av";
        }
        else if(this.sex==1)
        {
            return "selected_av";
        }
        return "";
      },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    bday: function () {
      
      return this.$store.getters.bday;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
    sales_point: function () {
      return this.$store.getters.sales_point;
    },
    title() {
        return this.$t('title')
      }
  },
  data: () => ({
    dialog_del:false,
    dialog_netw:false,
    dialog_skuch:false,
    show1:false,
    show2:false,
    show3:false,
    pass_change:false,
    password_old:null,
    password_new:null,
    password_new_confirm:null,
    edit:false,
    modal:false,
    sex:-1,
    date_e:'',
    fname_e:'', 
    lname_e:'',
    sku_change_allowed:false,
    net_allowed:false,
    del_reason:null,
    rating:0,
    ttype:'KA Network'

  }), 
  methods: {
    pass_set_new:function(){
            axios
      .post(
        API_LOCATION,
        {
          
          action: "pass_set_new",
          pass_old:this.password_old,
          pass_new1: this.password_new,
          pass_new2: this.password_new_confirm
          
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.pass_set!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
          alert(this.$t('success_change'));
          this.pass_change=false;
          this.logout();

        }
      });


    
  },
    sex_set(sex){
      this.sex=sex
        if(sex==0)
        {
          console.log(sex);
        } 
        else if(sex==1)
        {
            console.log(sex);
        }
    }
    ,
    getHeaderDateFormat(isoDate) {
      var month=["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"];
      //console.log(parseInt(isoDate.split('-')[1]));
      return month[parseInt(isoDate.split('-')[1])-1];
    },
    getMonthFormat: function(isoDate) {
      var month=["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"];
      //console.log(parseInt(isoDate.split('-')[1]));
      return month[parseInt(isoDate.split('-')[1])-1];
    }, 
    getTitleDateFormat: function(isoDate) {
       var month=["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"];
       
      //console.log(parseInt(isoDate.split('-')[1]));
      return month[parseInt(isoDate.split('-')[1])-1];
    },

    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    save_data: function () {
        let fname_e = this.fname_e
        let lname_e = this.lname_e 
        let date_e = this.date_e
        let sex_e=this.sex
        
        axios.post(API_LOCATION,  { data: 'component_data',action: 'update_profile', udata:{fname_e,lname_e,date_e,sex_e} },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{ 
        
           if(resp.data=='wrong_bday'){
             alert('Вибачте, мінімальний вік для участі в програмі – 21 рік');
             return;
           }
           if(resp.data=='not_all_fields'){
             alert('Заповніть усі поля та оберіть іконку.');
             return;
           }

          this.$store.dispatch('profileset')
          console.log(resp.data)       
          this.$router.push({ name: 'Main'})
           })
       
      },
    get_pending_profile: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_pending_profile"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            if(resp.data==0)
            {
             this.sex_set(this.avatar);
             this.edit=true;
            }
        
      });

    },
    get_allowed_actions: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_allowed_actions"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            console.log('ACTIONS',resp.data)
            this.sku_change_allowed=resp.data.sku_change_allowed
            this.net_allowed=resp.data.net_allowed
        
      });

    },

    get_rating: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_rating"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            this.rating=resp.data
            console.log('RATING'+resp.data);
        
      });

    },
    
    get_user_type: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_user_type"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            if(resp.data==1)
            {
              this.ttype='Учасник iTeam'
            }
            else if(resp.data==2)
            {
              this.ttype='Мережевий учасник iTeam'
              this.net_allowed=false
            }
            else if(resp.data==3)
            {
              this.ttype='TIP мережа'
              this.net_allowed=false
            }
            else if(resp.data==4)
            {
              this.ttype='Мережа KeyAccount'
              this.net_allowed=false
            }
            console.log('user_type'+resp.data);
        
      });

    },
    
    send_net_request: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "net_connect_request"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            console.log(resp);
            this.$router.push({ name: 'Main'})
        
      });

    },
    send_delete_request: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "delete_request",
          reason: this.del_reason
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            console.log(resp);
            this.$emit('pushe', {header:'Повідомлення',text:'Запит на видалення профілю надіслано.'});
            this.$router.push({ name: 'Main'})
        
      });

    },
    
  },
  created() {
   this.get_pending_profile();
   this.get_allowed_actions();
   this.get_rating();
   this.get_user_type();
   this.lname_e=this.$store.getters.lname;
   this.fname_e=this.$store.getters.fname;
   this.date_e=this.$store.getters.bday;

this.date=this.$store.getters.bday;
    this.$store.dispatch("profileset");
    this.$store.dispatch("page_title_set", "Профіль");  
    axios
      .post(
        API_LOCATION,
        {
          
          action: "api_check"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              console.log(resp.data);         
        
      });











  },
};
</script>   

<i18n>
{
  "ua": {
    "wrong_old":"Помилковий старий пароль",
"pass not match":"Паролі не співпадають",
    "pass insecure":"Довжина паролю має бути від 6 символів",
    "success_change":"Пароль успішно змінено, увійдіть до додатку з новим паролем",
"close":"Закрити"

  }
}
</i18n>